import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import type { Gym, Org, OrgMember, User, UserCredentials } from '@api/types'

type UseAuthState = {
  user: User | null
  credentials: UserCredentials | null
  gym: Gym | null
  org: Org | null
  tempPhoneNumber: string | null
  orgs: OrgMember[]
  setUser: (u: User) => void
  setCredentials: (c: UserCredentials) => void
  setGym: (gym: Gym | null) => void
  setOrg: (org: Org) => void
  setTempPhoneNumber: (tempPhoneNumber: string | null) => void
  clear: () => void
}

/**
 * see https://github.com/pmndrs/zustand/wiki/Persisting-the-store's-data#persist-middleware for docs on
 * zustand persist middleware
 * */

export const useAuthStore = create<UseAuthState>()(
  persist(
    (set, get) => ({
      user: null,
      credentials: null,
      gym: null,
      org: null,
      tempPhoneNumber: null,
      orgs: [],
      setUser: (user: User) => set({ user }),
      setCredentials: (credentials: UserCredentials) => set({ credentials }),
      setGym: (gym: Gym | null) => set({ gym }),
      setOrg: (org: Org) => set({ org }),
      setTempPhoneNumber: (tempPhoneNumber: string | null) => set({ tempPhoneNumber }),
      clear: () => set({ user: null, credentials: null, gym: null, org: null, orgs: [] })
    }),
    {
      name: 'helios.auth',
      version: 4
    }
  )
)

export function useBarre3() {
  return useAuthStore((state) => state?.user?.email.includes('barre3.com'))
}

export function useAuthUser() {
  return useAuthStore((state) => state.user)
}

export function useUserOrgs() {
  return useAuthStore((state) => state.orgs)
}

export function usePersistedGym(): [Gym | null, (gym: Gym | null) => void] {
  return useAuthStore((state) => [state.gym, state.setGym])
}

export function usePersistedOrg(): [Org | null, (org: Org) => void] {
  return useAuthStore((state) => [state.org, state.setOrg])
}

export function usePersistedPhoneNumber(): [
  string | null,
  (tempPhoneNumber: string | null) => void
] {
  return useAuthStore((state) => [state.tempPhoneNumber, state.setTempPhoneNumber])
}
