export const api = {
  host: process.env.NEXT_PUBLIC_API_HOST ?? 'https://api-dev.sendhelios.com'
}

export const baselime = {
  apiKey: process.env.NEXT_PUBLIC_BASELIME_API_KEY ?? ''
}

export const stage = process.env.NEXT_PUBLIC_STAGE ?? 'dev'

export const helpscout = {
  host: process.env.NEXT_PUBLIC_HELPSCOUT_API ?? 'https://docsapi.helpscout.net/v1',
  apiKey: process.env.NEXT_PUBLIC_HELPSCOUT_KEY ?? 'd056e02ff14601c04771cc265562e4b45f81da1e',
  helpCollection: '642c32581b7ba105b02eec11',
  blogCollection: '649ca773a15a23231a649351'
}

export const stripe = {
  publicKey:
    process.env.NEXT_PUBLIC_STRIPE_KEY ??
    'pk_test_51Is7xoHaDNUwq2Pj6DnWjs9mW13w8rPRyOiHQ1OtX23Z1rJ5eYwxMhDCatWT90HZ24POmARaCMDUL0Wdp1mdUODX00SDvxD9zE'
}

export const sanity = {
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION ?? '2023-09-19',
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET ?? 'production',
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ?? 'tgmhce8l'
}
