import { useAuthStore } from '@lib/auth'
import * as env from '@lib/env'
import { flattenObject } from '@lib/utils'
import hmac from 'crypto-js/hmac-sha512'
import { useCallback, useEffect, useRef } from 'react'
import useSWR, { type SWRResponse, type SWRConfiguration } from 'swr'
import useSWRInfinite, { type SWRInfiniteResponse } from 'swr/infinite'
import type {
  AnyKeys,
  Base,
  HttpBody,
  HttpHeaders,
  HttpMethod,
  HttpParams,
  ListParams,
  ReadParams,
} from './types'
import { ApiError } from './utils'

export class Service<Model extends Base> {
  basePath: string

  constructor(basePath: string) {
    this.basePath = basePath
  }

  async read(id: string, params?: ReadParams): Promise<Model> {
    return this.get(
      `${this.basePath}/${id}`,
      params ? flattenObject(params) : undefined
    ) as Promise<Model>
  }

  async create(body: AnyKeys<Model>): Promise<Model> {
    return this.post(`${this.basePath}`, body)
  }

  async list(params?: ListParams<Model>): Promise<Model[]> {
    return this.get(
      `${this.basePath}`,
      params ? flattenObject(params) : undefined
    ) as Promise<Model[]>
  }

  async listWithHeaders(
    params?: ListParams<Model>
  ): Promise<{ data: Model[]; headers: Headers }> {
    return this.getWithHeaders(
      `${this.basePath}`,
      params ? flattenObject(params) : undefined
    ) as Promise<{ data: Model[]; headers: Headers }>
  }

  async readAndUpdate(id: string, body: AnyKeys<Model>): Promise<Model> {
    return this.put(`${this.basePath}/${id}`, body)
  }

  async readAndDelete(id: string, params?: HttpParams): Promise<Model> {
    return this.delete(`${this.basePath}/${id}`, params)
  }

  async get<T>(path: string, params?: HttpParams): Promise<T> {
    return this.request({ path, method: 'get', params }) as Promise<T>
  }

  async getWithHeaders<T>(
    path: string,
    params?: HttpParams
  ): Promise<{ data: T; headers: Headers }> {
    return this.request({
      path,
      method: 'get',
      params,
      includeHeaders: true,
    }) as Promise<{
      data: T
      headers: Headers
    }>
  }

  async delete<T>(path: string, params?: HttpParams): Promise<T> {
    return this.request({ path, method: 'delete', params }) as Promise<T>
  }

  async post<T>(path: string, body?: HttpBody): Promise<T> {
    return this.request({ path, method: 'post', body }) as Promise<T>
  }

  async put<T>(path: string, body?: HttpBody): Promise<T> {
    return this.request({ path, method: 'put', body }) as Promise<T>
  }

  async patch<T>(path: string, body: HttpBody): Promise<T> {
    return this.request({ path, method: 'patch', body }) as Promise<T>
  }

  async request<T>(props: {
    path: string
    method: HttpMethod
    params?: HttpParams
    body?: HttpBody
    includeHeaders?: boolean
  }): Promise<T | { data: T; headers: Headers }> {
    // Build request
    const req = this.buildAuthRequest(props)

    // Issue request
    const res = await fetch(`${env.api.host}${req.path}`, {
      method: props.method.toUpperCase(),
      body: req.body,
      headers: {
        'content-type': 'application/json',
        ...req.headers,
      },
    })

    // Parse json response
    const json = await res.json()

    // Throw response as an error if we did not receive a 200
    if (!res.ok) {
      if (res.status === 401 && req.path !== '/auth/login') {
        this.handleUnauthorizedRequest()
      }
      throw new ApiError(res.status, json.message, json.error)
    }

    if (props.includeHeaders) {
      return { data: json, headers: res.headers }
    } else {
      return json
    }
  }

  private handleUnauthorizedRequest() {
    useAuthStore.getState().clear()
    const url = `/auth/login?from=${encodeURIComponent(window.location.pathname)}`
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    setTimeout(() => (window.location.href = url), 100)
  }

  private buildAuthRequest(props: {
    path: string
    method: HttpMethod
    params?: HttpParams
    body?: HttpBody
  }): { path: string; headers: HttpHeaders; body?: string } {
    const credentials = useAuthStore.getState().credentials

    // Migration step for new auth
    if (credentials && 'key' in credentials) {
      localStorage.removeItem('helios.auth')
      window.location.pathname = '/auth/login'
    }

    const timestamp = Date.now()

    let params = undefined
    let body = undefined

    switch (props.method) {
      case 'get':
      case 'delete':
      case 'options': {
        params = props.params
          ? Object.keys(props.params)
              .sort()
              .filter(
                (key: string) =>
                  props.params?.[key] !== undefined &&
                  props.params?.[key] !== null
              )
              .map((key: string) => [
                encodeURIComponent(key),
                encodeURIComponent(
                  props.params?.[key] as string | number | boolean
                ),
              ])
              .map(([key, value]) => `${key}=${value}`)
              .join('&')
          : ''
        break
      }
      case 'post':
      case 'put':
      case 'patch': {
        body = JSON.stringify(props.body ?? {})
        break
      }
      default:
        throw new Error('Invalid request method')
    }

    const signature = credentials
      ? hmac(
          `${timestamp}.${props.method}.${props.path}.${params ?? body}`,
          credentials.privateKey
        )
      : undefined

    return {
      path: [props.path, params].filter(Boolean).join('?'),
      body: body,
      headers: {
        authorization: credentials
          ? `bearer ${credentials.publicKey}`
          : undefined,
        'x-helios-timestamp': timestamp.toString(),
        'x-helios-signature': signature?.toString(),
      },
    }
  }
}

export type { SWRConfiguration }

// SWR middleware
function laggy(useSWRNext: any) {
  return (key: any, fetcher: any, config: any) => {
    // Use a ref to store previous returned data.
    const laggyDataRef = useRef(null)

    // Actual SWR hook.
    const swr = useSWRNext(key, fetcher, config)

    useEffect(() => {
      // Update ref if data is not undefined.
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data
      }
    }, [swr.data])

    // Expose a method to clear the laggy data, if any.
    const resetLaggy = useCallback(() => {
      laggyDataRef.current = null
    }, [])

    // Fallback to previous data if the current data is undefined.
    const dataOrLaggyData =
      swr.data === undefined ? laggyDataRef.current : swr.data

    // Is it showing previous data?
    const isLagging =
      swr.data === undefined && laggyDataRef.current !== undefined

    // Also add a `isLagging` field to SWR.
    return Object.assign({}, swr, {
      data: dataOrLaggyData,
      isLagging,
      resetLaggy,
    })
  }
}

export type APIConfiguration = Omit<SWRConfiguration, 'isPaused'> & {
  continue?: () => boolean
}

export function useRead<T extends Base>(
  service: Service<T>,
  id: string | undefined,
  params?: ReadParams,
  config?: APIConfiguration
): SWRResponse<T, Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => {
    if (config?.continue?.() === false) {
      throw new Error('continue error')
    }
    return service.read(id as string, params)
  }
  return useSWR(
    id === undefined ? null : [`/${service.basePath}/${id}`, cacheKey],
    fetcher,
    config
  )
}

export function useList<T extends Base>(
  service: Service<T>,
  params?: ListParams<T>,
  config?: APIConfiguration
): SWRResponse<T[], Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => {
    if (config?.continue?.() === false) {
      throw new Error('continue error')
    }
    return service.list(params)
  }
  return useSWR(
    [`/${service.basePath}`, cacheKey],
    fetcher,
    config
  ) as SWRResponse<T[], Error>
}

export function useListWithCount<T extends Base>(
  service: Service<T>,
  params?: ListParams<T>,
  config?: APIConfiguration
): SWRResponse<{ data: T[]; count: number }, Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = async () => {
    if (config?.continue?.() === false) {
      throw new Error('continue error')
    }
    const response = await service.listWithHeaders(params) // Pass true to include headers
    let count = 0
    if ('headers' in response) {
      count = Number.parseInt(
        response.headers.get('x-helios-meta-count') || '0',
        10
      )
    } else {
      // Handle the case where response does not have headers, if needed
    }
    return { data: response.data, count }
  }

  // Apply the middleware
  const swrConfig = {
    ...config,
    use: [laggy],
  }

  return useSWR([`/${service.basePath}`, cacheKey], fetcher, swrConfig)
}

export function useInfinite<T extends Base>(
  service: Service<T>,
  params?: ListParams<T>,
  config?: APIConfiguration
): SWRInfiniteResponse<T[], Error> {
  const LIMIT = params?.limit || 25
  const fetcher = (key: any) => {
    if (config?.continue?.() === false) {
      throw new Error('continue error')
    }
    const [_, cacheKey] = key
    const params = JSON.parse(cacheKey) as ListParams<T>
    return service.list(params)
  }
  const getKey = (pageIndex: number, previousPageData: T[] | null) => {
    if (previousPageData && !previousPageData.length) return null

    const offset = pageIndex * LIMIT
    const cacheKey = JSON.stringify({ ...params, limit: LIMIT, offset })

    return [`/${service.basePath}`, cacheKey]
  }
  return useSWRInfinite(getKey, fetcher, config)
}

export function useGet<T>(
  service: Service<any>,
  path: string | null,
  params?: HttpParams,
  config?: APIConfiguration
): SWRResponse<T, Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => {
    if (config?.continue?.() === false) {
      throw new Error('continue error')
    }
    return path ? service.get<T>(path, params) : Promise.resolve(null as T)
  }
  return useSWR([path, cacheKey], fetcher, config) as SWRResponse<T, Error>
}

export function useGetWithCount<T>(
  service: Service<any>,
  path: string,
  params?: HttpParams,
  config?: APIConfiguration
): SWRResponse<{ data: T; count: number }, Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = async () => {
    if (config?.continue?.() === false) {
      throw new Error('continue error')
    }
    const response = await service.getWithHeaders(path, params)
    let count = 0
    if ('headers' in response) {
      count = Number.parseInt(
        response.headers.get('x-helios-meta-count') || '0',
        10
      )
    }
    return { data: response.data, count }
  }

  // Apply the middleware
  const swrConfig = {
    ...config,
    use: [laggy],
  }

  return useSWR([path, cacheKey], fetcher, swrConfig) as SWRResponse<
    { data: T; count: number },
    Error
  >
}
