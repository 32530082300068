import * as analytics from '@/lib/analytics'
import { useEffect } from 'react'
import { Service } from '@api/common'
import type { AuthResponse, User, UserCredentials } from './types'
import { useAuthStore } from '@lib/auth'

export type { User, UserCredentials }

export class AuthService extends Service<UserCredentials> {
  constructor() {
    super('/auth')
  }

  async checkEmail(email: string): Promise<any> {
    const res: { email: string; exists: boolean } = await this.post('/auth/email', { email })
    return res
  }

  async signup(body: {
    firstName: string
    lastName: string
    phoneNumber?: string
    email: string
    password: string
  }): Promise<AuthResponse> {
    const res: AuthResponse = await this.post('/auth/signup', body)
    useAuthStore.setState(res)
    return res
  }

  async login(email: string, password: string): Promise<AuthResponse> {
    const res = await this._login(email, password)
    useAuthStore.setState(res)
    return res
  }

  async forgotPassword(email: string): Promise<void> {
    await this.post('/auth/forgot', { email })
  }

  async resetPassword(token: string, password: string): Promise<AuthResponse> {
    const res: AuthResponse = await this.post('/auth/reset', { token, password })
    useAuthStore.setState(res)
    return res
  }

  async refreshCredentials(): Promise<AuthResponse> {
    const res: AuthResponse = await this.post('/auth/refresh', {})
    useAuthStore.setState(res)
    return res
  }

  private async _login(email: string, password: string): Promise<AuthResponse> {
    return this.post('/auth/login', { email, password })
  }

  logout() {
    useAuthStore.getState().clear()
    analytics.reset()
  }
}

export const authService = new AuthService()

const THREE_DAYS = 3 * 24 * 60 * 60 * 1000

export function useAuthRefresh() {
  const credentials = useAuthStore((state) => state.credentials)
  useEffect(() => {
    if (!credentials) {
      return
    }
    const diff = new Date(credentials.expiresAt).getTime() - Date.now()
    if (diff < THREE_DAYS) {
      authService.refreshCredentials()
    }
  }, [credentials])
}
