import * as React from 'react'

import { cn } from '@/lib/utils'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  leadingIcon?: React.ReactNode
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <div className="relative">
        {props.leadingIcon && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            {props.leadingIcon}
          </div>
        )}
        <input
          type={type}
          className={cn(
            'flex w-full rounded-md border border-slate-300 bg-background p-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:border-border focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-400 focus-visible:border-slate-400 disabled:cursor-not-allowed disabled:opacity-50',
            props.leadingIcon ? 'pl-10' : '',
            className
          )}
          ref={ref}
          {...props}
        />
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
