import posthog from 'posthog-js'
import type { User } from '@api/types'

const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY
const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST

export function init() {
  if (!posthogKey) {
    return
  }
  try {
    posthog.init(posthogKey, {
      api_host: posthogHost,
      person_profiles: 'identified_only',
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug()
      },
    })
  } catch (err) {
    console.error('[posthog] init:', err)
  }
}

export function identify(user: User) {
  if (!posthogKey) {
    return
  }
  try {
    posthog.identify(user.id, {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
    })
  } catch (err) {
    console.error('[posthog] identify:', err)
  }
}

export const client = posthog
