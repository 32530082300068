import { create } from 'zustand'

type UseBlockerState = {
  open: boolean
  setOpen: (open: boolean) => void
}

export const useBlockerStore = create<UseBlockerState>()((set) => ({
  open: false,
  setOpen: (open) => set(() => ({ open }))
}))

export function useBlocker() {
  return useBlockerStore()
}
