import type { User } from '@api/types'
import mixpanel, { type Dict, type RequestOptions } from 'mixpanel-browser'

const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN

export function init() {
  if (!mixpanelToken) {
    return
  }
  try {
    mixpanel.init(mixpanelToken, { debug: true, track_pageview: true })
  } catch (err) {
    console.error('[mixpanel] init:', err)
  }
}

export function identify(user: User) {
  if (!mixpanelToken) {
    return
  }
  try {
    mixpanel.identify(user.id)
    mixpanel.people?.set({
      $email: user.email,
      $first_name: user.firstName,
      $last_name: user.lastName,
    })
  } catch (err) {
    console.error('[mixpanel] identify:', err)
  }
}

export function track(eventName: string, props?: Dict, options?: RequestOptions) {
  if (!mixpanelToken) {
    return
  }
  try {
    mixpanel.track(eventName, props, options)
  } catch (err) {
    console.error('[mixpanel] track:', err)
  }
}

export function reset() {
  if (!mixpanelToken) {
    return
  }
  mixpanel.reset()
}
