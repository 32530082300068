import toast from 'react-hot-toast'
import type { Base } from './types'
import { useBlockerStore } from '@lib/blocker'
import { ICreatePayload } from '@/lib/public-api'
import { omit } from 'lodash'

export type Ref<T> = (Partial<T> & ({ _id: string } | { id: string })) | string

export type ErrorData = {
  [key: string]: any
}

export class ApiError extends Error {
  private _status: number
  private _data: ErrorData | null

  constructor(status: number, message: string, data: ErrorData | null = null) {
    super(message)
    this._status = status
    this._data = data
  }

  get status() {
    return this._status
  }

  get data() {
    return this._data
  }
}

export function mergeApiArray<T extends Base>(arr: T[], item: T): T[] {
  let replaced = false
  const res = []
  for (const _item of arr) {
    if (_item.id === item.id) {
      replaced = true
      res.push(item)
    } else {
      res.push(_item)
    }
  }
  if (!replaced) {
    res.push(item)
  }
  return res
}

export function objectId<T = unknown>(input: Ref<T>): string {
  if (typeof input === 'string') {
    return input
  }
  if ('_id' in input) {
    return input._id
  }
  if ('id' in input) {
    return input.id
  }
  throw new Error('Input is not a valid ref. Missing both _id and id')
}

export function handleApiError(err: ApiError, defaultMessage?: string) {
  if (err.status === 402) {
    useBlockerStore.setState({ open: true })
  } else {
    toast.error(err?.message ?? defaultMessage, {
      duration: 100000,
      position: 'bottom-center',
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff'
      }
    })
  }
}

export function mapFormToApiSubmission(data: any): ICreatePayload {
  const customer = {
    phoneNumber: data.phoneNumber,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName
  }

  if (data.fullName) {
    const [firstName, ...lastName] = data.fullName.trim().split(' ')
    customer.firstName = firstName
    customer.lastName = lastName.join(' ')
  }

  const fields = Object.keys(data)
    .filter((key) => !Object.keys(customer).includes(key))
    .map((key) => ({
      name: key,
      value: data[key]
    }))

  const metadata: Record<string, any> = {}

  try {
    const url =
      window.location != window.parent.location ? document.referrer : document.location.href
    metadata.referrer = url
  } catch (e) {
    console.warn(e)
  }

  return {
    customer,
    fields,
    metadata
  }
}

export function getParamsWithPagination(
  params?: Record<string, any> & { page?: number | null; limit?: number | null },
  opts?: { limit?: number }
) {
  let page = 1
  if (params && typeof params?.page === 'number') {
    page = Number(params.page)
  }
  const limit = params?.limit ?? opts?.limit ?? 100
  const offset = (page - 1) * limit
  return omit(
    {
      ...params,
      offset,
      limit
    },
    'page'
  )
}