import type { User } from '@/api/types'
import * as mixpanel from '@lib/mixpanel'
import * as koala from '@lib/koala'
import * as posthog from '@lib/posthog'

export function init() {
  posthog.init()
  mixpanel.init()
  koala.init()
}

export function track(eventName: string, props?: Record<string, any>) {
  posthog.client.capture(eventName, props)
  mixpanel.track(eventName, props)
  koala.track(eventName, props)
}

export function identify(user: User) {
  posthog.identify(user)
  mixpanel.identify(user)
  koala.identify(user)
}

export function reset() {
  posthog.client.reset()
  mixpanel.reset()
  koala.reset()
}
